<template>
  <div>
    <a-form
      :model="formState"
      :rules="rules"
      layout="vertical"
      :scroll-to-first-error="true"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <div class="row">
        <div class="col-lg-6">
          <a-form-item label="Vendor" name="vendorId">
            <a-select
              placeholder="Please select vendor"
              v-model:value="formState.vendorId"
              :disabled="isEdit"
            >
              <a-select-option v-for="(data, idx) in vendorList" :key="idx" :value="data.id">
                {{ data.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div class="col-lg-6">
          <a-form-item label="Service Type" name="serviceType">
            <a-radio-group v-model:value="formState.serviceType">
              <a-radio-button
                v-for="(value, name, idx) in serviceTypesObj"
                :key="idx"
                :value="value"
              >
                {{ name }}
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <a-form-item label="Service Name" name="name">
            <a-input
              v-model:value="formState.name"
              placeholder="Please enter service name"
              allow-clear
            />
          </a-form-item>
        </div>
        <div class="col-lg-6">
          <a-form-item label="Alias" name="alias" has-feedback>
            <a-input
              v-model:value="formState.alias"
              placeholder="Enter alias in lowercase without spaces"
              allow-clear
              @blur="onAliasInputBlur"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6" v-show="!isEdit">
          <a-form-item label="Description" name="description">
            <a-textarea
              v-model:value="formState.description"
              :rows="4"
              placeholder="please enter url description"
              allow-clear
            />
          </a-form-item>
        </div>
        <div class="col-lg-6">
          <div>
            <a-form-item label="Vendor Service Code" name="serviceCode" has-feedback>
              <a-input
                v-model:value="formState.serviceCode"
                placeholder="Enter vendor service code in lowercase"
                allow-clear
              />
            </a-form-item>
          </div>
        </div>
      </div>
      <div>
        <a-button html-type="submit" class="btn btn-primary px-5 mr-3" :loading="loading">
          Save
        </a-button>
      </div>
    </a-form>
  </div>
</template>
<script lang="ts">
import { VendorServiceTypes } from '@/types/enums/VendorServiceTypes'
import { VendorServiceFormData } from '@/types/appcontracts/VendorServiceFormData'
import { computed, defineComponent, PropType, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import * as actions from '../../../../../store/actions.type'
import { VendorsSearchDocument } from '@/types/firebaseCollectionContracts/VendorsSearchDocument'
import { IVendorServiceSearchMapping } from '@/types/interfaces/IVendorServiceSearchMapping'

const getInitialState = (editData: IVendorServiceSearchMapping | null = null) => {
  const initialFormState: VendorServiceFormData = {
    vsid: null,
    vendorId: editData?.vendorId || null,
    serviceType: editData?.type || VendorServiceTypes.Domestic,
    name: editData?.name || null,
    alias: editData?.alias || null,
    weightLimit: 0,
    serviceCode: editData?.serviceCode || null,
    description: null,
  }

  return initialFormState
}

export default defineComponent({
  name: 'VendorServiceForm',
  props: {
    editData: {
      type: Object as PropType<IVendorServiceSearchMapping | null>,
      required: false,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore()
    const isEdit = computed(() => props.editData !== null && props.editData !== undefined)
    const vendorList = computed(
      () => store.state.vendors.vendorsSearchList as VendorsSearchDocument,
    )
    const vendorServicesAliasList = computed<Array<string | null>>(
      () => store.getters['vendorServices/vendorServicesAliasList'],
    )
    const loading = ref(false)
    const fetchedAlias = computed(() => props.editData?.alias)
    const serviceTypesObj = VendorServiceTypes

    const formState = reactive<VendorServiceFormData>(getInitialState(props.editData))
    const isAliasUnique = async () => {
      return fetchedAlias.value !== formState.alias &&
        vendorServicesAliasList.value.includes(formState.alias)
        ? Promise.reject('Alias already exists.')
        : Promise.resolve(true)
    }

    const rules = {
      vendorId: [{ required: true, message: 'Please select a Vendor', trigger: 'change' }],
      serviceType: [{ required: true, message: 'Please select service type', trigger: 'blur' }],
      name: [
        {
          required: true,
          type: 'string',
          message: 'Please add name of the service',
          trigger: 'blur',
        },
      ],
      alias: [
        {
          required: true,
          type: 'string',
          min: 1,
          message: 'Please input valid alias(min length 1)',
          trigger: 'blur',
        },
        { validator: isAliasUnique, trigger: 'blur' },
      ],
    }

    const handleFinish = async () => {
      loading.value = true
      const docOperation: DocumentOperation<VendorServiceFormData> = {
        action: isEdit.value ? DocumentActions.UPDATE : DocumentActions.CREATE,
        id: isEdit.value ? props.editData?.id || null : null,
        payload: toRaw(formState),
        audit: null,
      }
      await store.dispatch(
        `vendorServices/${actions.VendorServicesAction.SET_OR_DELETE_VENDOR_SERVICE}`,
        docOperation,
      )
      loading.value = false
      Object.assign(formState, getInitialState())
    }

    // TODO: Remove if not needed.
    const handleFinishFailed = async (errors: any) => {
      console.log('ERR: ', errors)
    }

    const onAliasInputBlur = () => {
      // TODO: Check for unique alias from store
      formState.alias = formState.alias?.replace(/\s/g, '').toLowerCase() ?? null
    }

    return {
      formState,
      rules,
      vendorList,
      serviceTypesObj,
      loading,
      handleFinish,
      handleFinishFailed,
      onAliasInputBlur,
      isEdit,
    }
  },
})
</script>

<style lang="less" scoped></style>
