<template>
  <div class="mb-3">
    <a-alert
      message="Not all results have been listed"
      :description="alertMessage"
      type="warning"
      show-icon
      :closable="false"
      v-if="alertMessage"
      @close="() => (alertMessage = '')"
    />
  </div>
  <div class="table-responsive">
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :bordered="true"
      :row-key="(rec) => rec.id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleChange"
      :scroll="{ x: true }"
    >
      <template #expandedRowRender="{record}">
        <div class="p-3">
          <div><h5>Services</h5></div>
          <vendor-services-table :vendor-id="record.id" />
        </div>
      </template>

      <template #photo="{text}">
        <div class="d-flex flex-wrap align-items-center justify-content-center">
          <div class="flex-shrink-0">
            <a-avatar
              shape="square"
              size="default"
              style="color: #595c97; background-color: #f2f4f8;"
              :src="text"
            >
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>
        </div>
      </template>
      <template #vendorType="{text}">
        <span class="align-middle text-primary text-uppercase font-size-12 badge badge-light">
          {{ text || 'N/A' }}
        </span>
      </template>
      <template #alias="{text}">
        <a-tag color="blue">
          <span class="align-middle font-size-14">
            {{ text || 'N/A' }}
          </span>
        </a-tag>
      </template>
      <template #actions="{record}">
        <div>
          <ButtonGroupActions
            :button-list="actionButtonList"
            @handleViewClick="handleViewClick(record)"
            @handleEditClick="handleEditClick(record)"
            @handleDeleteClick="handleDeleteClick(record)"
          />
        </div>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, defineComponent, reactive, ref } from 'vue'
import * as actions from '@/store/actions.type'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { VendorsDocument } from '@/types/firebaseCollectionContracts/VendorsDocument'
import { UserOutlined } from '@ant-design/icons-vue'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import VendorServicesTable from './VendorServicesTable.vue'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'

const columns = [
  {
    title: '',
    dataIndex: 'doc.photoUrl',
    key: 'photoUrl',
    slots: { customRender: 'photo' },
  },
  {
    title: 'Vendor ID',
    dataIndex: 'doc.vid',
    key: 'vid',
  },
  {
    title: 'Type',
    dataIndex: 'doc.vendorType',
    key: 'vendorType',
    slots: { customRender: 'vendorType' },
  },
  {
    title: 'Alias',
    dataIndex: 'doc.alias',
    key: 'alias',
    slots: { customRender: 'alias' },
  },
  {
    title: 'Name',
    dataIndex: 'doc.displayName',
    key: 'displayName',
  },
  {
    title: 'Email',
    dataIndex: 'doc.email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: 'Mobile',
    dataIndex: 'doc.mobile',
    key: 'mobile',
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'VendorsTable',
  components: { UserOutlined, ButtonGroupActions, VendorServicesTable },
  props: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const tableLoading = ref(false)
    const alertMessage = ref('')
    const vendorServicesList = computed(() => store.state.vendorServices.vendorServicesSearchList)
    const actionButtonList = [ActionButtonList.VIEW, ActionButtonList.EDIT, ActionButtonList.DELETE]

    const totalTableRecords = computed(() => {
      return store.state.vendors.vendorsSearchList?.length
    })
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }
    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: totalTableRecords,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })
    const vendorList = computed<IAppDocument<VendorsDocument>[]>(
      () => store.getters['vendors/vendorList'] as IAppDocument<VendorsDocument>[],
    )

    const dataSource = computed(() => {
      return vendorList.value
    })

    const handleViewClick = (record: IAppDocument<VendorsDocument>) => {
      router.push({ name: 'vendorProfile', params: { id: record.id } })
    }

    const handleChange = async (page: any) => {
      tableLoading.value = true
      const required: number = page.current * page.pageSize

      if (required > vendorList.value.length) {
        const fetchSize = required - vendorList.value.length
        await store.dispatch(`vendors/${actions.VendorsAction.FETCH_VENDORS_FROM_SERVER}`, {
          fetchSize,
        })
      }
      tableLoading.value = false
    }

    const handleEditClick = (record: any) => {
      router.push({ name: 'editVendor', params: { id: record.id } })
    }

    const handleDeleteClick = async (record: IAppDocument<any>) => {
      tableLoading.value = true
      const docOperation: DocumentOperation<null> = {
        action: DocumentActions.DELETE,
        id: record.id,
        payload: null,
        audit: null,
      }
      await store.dispatch(`vendors/${actions.VendorsAction.SET_OR_DELETE_VENDOR}`, docOperation)
      tableLoading.value = false
    }

    return {
      columns,
      dataSource,
      handleViewClick,
      pagination,
      handleChange,
      tableLoading,
      alertMessage,
      vendorServicesList,
      actionButtonList,
      handleEditClick,
      handleDeleteClick,
    }
  },
})
</script>

<style lang="scss">
.ant-pagination-item,
.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  display: none !important;
}
</style>
