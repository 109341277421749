
import { useStore } from 'vuex'
import { computed, ref, defineComponent } from 'vue'
import VendorsTable from './VendorsTable.vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import * as actions from '@/store/actions.type'
import ModalSlot from '@/components/General/ModalSlot.vue'
import VendorServiceForm from './services/new/index.vue'

export default defineComponent({
  name: 'Vendors',
  components: { SyncOutlined, VendorsTable, ModalSlot, VendorServiceForm },
  setup() {
    const store = useStore()
    const isVendorsLoading = computed(() => store.state.vendors.vendorsLoading)
    const vendorsExists = computed(() => store.getters['vendors/vendorsExists'])
    const reloadIconType = ref(false)
    const isServiceModalVisible = ref(false)

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`vendors/${actions.VendorsAction.FETCH_VENDORS_ON_LOAD}`)
      reloadIconType.value = false
    }

    return {
      isVendorsLoading,
      vendorsExists,
      reloadIconType,
      handleDataReload,
      isServiceModalVisible,
    }
  },
})
