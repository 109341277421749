
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, defineComponent, reactive, ref } from 'vue'
import * as actions from '@/store/actions.type'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { VendorsDocument } from '@/types/firebaseCollectionContracts/VendorsDocument'
import { UserOutlined } from '@ant-design/icons-vue'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import VendorServicesTable from './VendorServicesTable.vue'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'

const columns = [
  {
    title: '',
    dataIndex: 'doc.photoUrl',
    key: 'photoUrl',
    slots: { customRender: 'photo' },
  },
  {
    title: 'Vendor ID',
    dataIndex: 'doc.vid',
    key: 'vid',
  },
  {
    title: 'Type',
    dataIndex: 'doc.vendorType',
    key: 'vendorType',
    slots: { customRender: 'vendorType' },
  },
  {
    title: 'Alias',
    dataIndex: 'doc.alias',
    key: 'alias',
    slots: { customRender: 'alias' },
  },
  {
    title: 'Name',
    dataIndex: 'doc.displayName',
    key: 'displayName',
  },
  {
    title: 'Email',
    dataIndex: 'doc.email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: 'Mobile',
    dataIndex: 'doc.mobile',
    key: 'mobile',
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'VendorsTable',
  components: { UserOutlined, ButtonGroupActions, VendorServicesTable },
  props: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const tableLoading = ref(false)
    const alertMessage = ref('')
    const vendorServicesList = computed(() => store.state.vendorServices.vendorServicesSearchList)
    const actionButtonList = [ActionButtonList.VIEW, ActionButtonList.EDIT, ActionButtonList.DELETE]

    const totalTableRecords = computed(() => {
      return store.state.vendors.vendorsSearchList?.length
    })
    const showTotal = (total: number, range: Array<any>) => {
      return `${range[0]}-${range[1]} of ${total} items`
    }
    const pagination = reactive({
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      total: totalTableRecords,
      simple: false,
      pageSize: 10,
      showTotal: showTotal,
    })
    const vendorList = computed<IAppDocument<VendorsDocument>[]>(
      () => store.getters['vendors/vendorList'] as IAppDocument<VendorsDocument>[],
    )

    const dataSource = computed(() => {
      return vendorList.value
    })

    const handleViewClick = (record: IAppDocument<VendorsDocument>) => {
      router.push({ name: 'vendorProfile', params: { id: record.id } })
    }

    const handleChange = async (page: any) => {
      tableLoading.value = true
      const required: number = page.current * page.pageSize

      if (required > vendorList.value.length) {
        const fetchSize = required - vendorList.value.length
        await store.dispatch(`vendors/${actions.VendorsAction.FETCH_VENDORS_FROM_SERVER}`, {
          fetchSize,
        })
      }
      tableLoading.value = false
    }

    const handleEditClick = (record: any) => {
      router.push({ name: 'editVendor', params: { id: record.id } })
    }

    const handleDeleteClick = async (record: IAppDocument<any>) => {
      tableLoading.value = true
      const docOperation: DocumentOperation<null> = {
        action: DocumentActions.DELETE,
        id: record.id,
        payload: null,
        audit: null,
      }
      await store.dispatch(`vendors/${actions.VendorsAction.SET_OR_DELETE_VENDOR}`, docOperation)
      tableLoading.value = false
    }

    return {
      columns,
      dataSource,
      handleViewClick,
      pagination,
      handleChange,
      tableLoading,
      alertMessage,
      vendorServicesList,
      actionButtonList,
      handleEditClick,
      handleDeleteClick,
    }
  },
})
