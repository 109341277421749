<template>
  <div>
    <div class="table-responsive">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :bordered="true"
        :row-key="(rec, idx) => idx"
        size="small"
        :loading="tableLoading"
      >
        <template #serviceType="{text}">
          <span class="align-middle text-primary text-uppercase font-size-12 badge badge-light">
            {{ text || 'N/A' }}
          </span>
        </template>
        <template #alias="{text}">
          <a-tag color="blue">
            <span class="align-middle font-size-14">
              {{ text || 'N/A' }}
            </span>
          </a-tag>
        </template>
        <template #actions="{record}">
          <div>
            <ButtonGroupActions
              :button-list="actionButtonList"
              @handleEditClick="handleEditClick(record)"
              @handleDeleteClick="handleDeleteClick(record)"
            />
          </div>
        </template>
      </a-table>
    </div>
    <div>
      <ModalSlot
        v-model:modal-visible="isServiceModalVisible"
        modal-title="Update Vendor Service"
        modal-width="800"
      >
        <VendorServiceForm :edit-data="editData" />
      </ModalSlot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import { IVendorServiceSearchMapping } from '@/types/interfaces/IVendorServiceSearchMapping'
import ModalSlot from '@/components/General/ModalSlot.vue'
import VendorServiceForm from './services/new/index.vue'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Alias',
    dataIndex: 'alias',
    key: 'alias',
    slots: { customRender: 'alias' },
  },
  {
    title: 'Service Code',
    dataIndex: 'serviceCode',
    key: 'serviceCode',
    slots: { customRender: 'alias' },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    slots: { customRender: 'serviceType' },
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  name: 'VendorServicesTable',
  components: { ButtonGroupActions, ModalSlot, VendorServiceForm },
  props: {
    vendorId: {
      type: String,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore()
    const tableLoading = ref<boolean>(false)
    const actionButtonList = [ActionButtonList.EDIT, ActionButtonList.DELETE]
    const isServiceModalVisible = ref<boolean>(false)
    const { vendorServicesSearchList } = useSearchMapping()
    const dataSource = computed(() =>
      vendorServicesSearchList.value.filter((x) => x.vendorId === props.vendorId),
    )
    const editData = ref<IVendorServiceSearchMapping | null>(null)
    const handleEditClick = (record: IVendorServiceSearchMapping) => {
      editData.value = record
      isServiceModalVisible.value = true
    }

    const handleDeleteClick = async (record: IVendorServiceSearchMapping) => {
      tableLoading.value = true
      const docOperation: DocumentOperation<null> = {
        action: DocumentActions.DELETE,
        id: record.id,
        payload: null,
        audit: null,
      }
      await store.dispatch(
        `vendorServices/${actions.VendorServicesAction.SET_OR_DELETE_VENDOR_SERVICE}`,
        docOperation,
      )
      tableLoading.value = false
    }

    return {
      columns,
      actionButtonList,
      dataSource,
      handleEditClick,
      handleDeleteClick,
      isServiceModalVisible,
      editData,
      tableLoading,
    }
  },
})
</script>

<style lang="scss">
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #f0f2f40d !important;
}
</style>
