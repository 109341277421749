<template>
  <div>
    <div class="d-flex flex-wrap border-bottom mb-3">
      <div class="mt-4 mr-auto">
        <h4>Vendors</h4>
      </div>
      <router-link
        :to="{ name: 'newVendor' }"
        class="btn btn-success btn-with-addon text-nowrap my-3 mr-3"
      >
        <span class="btn-addon">
          <i class="btn-addon-icon fe fe fe-user-plus"></i>
        </span>
        New
      </router-link>
      <div>
        <div
          class="btn btn-success text-blue btn-with-addon text-nowrap my-3 mr-3"
          @click="isServiceModalVisible = true"
        >
          <span class="btn-addon">
            <i class="btn-addon-icon fe fe fe-plus"></i>
          </span>
          Service
        </div>
      </div>
      <div class="dropdown my-3 mr-3">
        <a-dropdown placement="bottomLeft" :trigger="['click']">
          <a class="btn btn-light text-blue dropdown-toggle" href="javascript: void(0);">Actions</a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <router-link
                  :to="{
                    name: 'importFile',
                    query: { entity: 'vendors' },
                  }"
                >
                  Import
                </router-link>
                <!-- <a href="javascript:;">Import</a> -->
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">Export</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <a-tooltip title="Refresh">
          <button type="button" class="btn btn-light" @click="handleDataReload">
            <SyncOutlined :spin="reloadIconType" />
          </button>
        </a-tooltip>
      </div>
    </div>
    <div>
      <a-skeleton :loading="isVendorsLoading" active>
        <div class="text-center my-5" v-if="!vendorsExists">
          <h3>Business is no fun without people.</h3>
          <p class="text-muted">
            Create and manage your contacts, all in one place.
          </p>
          <!-- <template> -->
          <div>
            <a-empty :description="false" />
          </div>
          <!-- </template> -->
          <router-link :to="{ name: 'newVendor' }" class="btn btn-success my-2 text-uppercase"
            >Create New Vendor
          </router-link>
        </div>
        <div v-else>
          <div>
            <VendorsTable />
          </div>
        </div>
      </a-skeleton>
    </div>

    <div>
      <ModalSlot
        v-model:modal-visible="isServiceModalVisible"
        modal-title="Add Vendor Service"
        modal-width="800"
      >
        <VendorServiceForm />
      </ModalSlot>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { computed, ref, defineComponent } from 'vue'
import VendorsTable from './VendorsTable.vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import * as actions from '@/store/actions.type'
import ModalSlot from '@/components/General/ModalSlot.vue'
import VendorServiceForm from './services/new/index.vue'

export default defineComponent({
  name: 'Vendors',
  components: { SyncOutlined, VendorsTable, ModalSlot, VendorServiceForm },
  setup() {
    const store = useStore()
    const isVendorsLoading = computed(() => store.state.vendors.vendorsLoading)
    const vendorsExists = computed(() => store.getters['vendors/vendorsExists'])
    const reloadIconType = ref(false)
    const isServiceModalVisible = ref(false)

    const handleDataReload = async () => {
      reloadIconType.value = true
      await store.dispatch(`vendors/${actions.VendorsAction.FETCH_VENDORS_ON_LOAD}`)
      reloadIconType.value = false
    }

    return {
      isVendorsLoading,
      vendorsExists,
      reloadIconType,
      handleDataReload,
      isServiceModalVisible,
    }
  },
})
</script>
